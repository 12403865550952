@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONT FAMILY START */

@font-face {
  font-family: "SF";
  src: url("../public/fonts/SF-Pro-Display-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "SF";
  src: url("../public/fonts/SF-Pro-Display-Regular.otf");
  font-weight: 400;
}

/* FONT FAMILY END */

body {
  @apply bg-white dark:bg-[#121618];
  font-family: "SF", sans-serif;
  font-weight: 500;
}

.Inter-400 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.Inter-500 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.Inter-600 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.container {
  margin: auto;
}

/* SCROLLBAR START */
::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 4px;
  max-height: 10px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #898686;
  max-height: 5px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* SCROLLBAR END */

.shake {
  animation: shakeCart 0.2s ease-in-out forwards;
  transform: translate(180px, -160px) scale(0.5);
}

@keyframes shakeCart {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.basketAnime {
  animation: basketAnimation 0.2s ease-in-out forwards;
  animation-delay: 0.9s;
}

@keyframes basketAnimation {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Responsive Design */

@media (min-width: 470px) {
  .btn-400 {
    font-size: 20px;
    padding: 15px 50px;
  }
  .btn-count-right {
    padding-left: 18px;
    padding-right: 30px;
  }
  .btn-count-right svg {
    font-size: 26px;
  }
  .btn-count-left {
    padding-right: 18px;
    padding-left: 30px;
  }
  .btn-count-left svg {
    font-size: 26px;
  }
  .count-400 {
    font-size: 26px;
    width: 50px;
  }
  .product-title {
    font-size: 30px;
  }
  .product-price {
    margin-top: 10px;
    font-size: 16px;
  }
  .product-text {
    margin-top: 15px;
    line-height: 22px;
    font-size: 16px;
  }
  .product-btns {
    margin-top: 30px;
  }
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  @apply dark:!bg-white;
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.fixed-footer {
  background: #fff;
  width: 100%;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  bottom: 0;
}
/* 
input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
} */
